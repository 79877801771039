import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSocket } from '../hooks/useSocket';
import { Quotation } from '../@types/interface';
import { QUOTATION } from '../constants';
import { getAllMyQuotationsController } from '../pages/common/Quotation/workshop/controller';

interface QuotationState {
  notAnsweredQuotationList: Quotation[];
  answeredQuotationList: Quotation[];
  finishedQuotationList: Quotation[];
}

export type QuotationPages = 'created' | 'replies' | 'completed';
interface QuotationContextType {
  quotationState: QuotationState;
  setQuotationState: Dispatch<SetStateAction<QuotationState>>;
  selectedPage: QuotationPages;
  setSelectedPage: Dispatch<SetStateAction<QuotationPages>>;
  updateQuotations: (quotationToUpdate: Quotation) => void;
  finishQuotation: (quotationToRemove: Quotation) => void;
}

const QuotationContext = createContext<QuotationContextType | null>(null);

export const QuotationProvider = ({ children }: { children: ReactNode }) => {
  const { socketInstance } = useSocket();

  const [quotationState, setQuotationState] = useState<QuotationState>({
    notAnsweredQuotationList: [],
    answeredQuotationList: [],
    finishedQuotationList: [],
  });

  const [selectedPage, setSelectedPage] = useState<QuotationPages>('created');

  const fetchQuotations = useCallback(async () => {
    const res = await getAllMyQuotationsController({
      limit: QUOTATION.REQUEST_LIMIT,
      page: 1,
    });

    if (res) {
      const { answered, finished, not_answered } = res;
      setQuotationState({
        notAnsweredQuotationList: not_answered,
        answeredQuotationList: answered,
        finishedQuotationList: finished,
      });
    }
  }, []);

  const addNewQuotation = (
    newQuotation: Quotation,
    existingQuotations: Quotation[],
  ): Quotation[] => {
    const alreadyExists = existingQuotations.some(
      quotation => quotation.id_quotation === newQuotation.id_quotation,
    );

    if (alreadyExists) {
      // update quotation with the same id
      return existingQuotations.map(previousQuotation => {
        if (previousQuotation.id_quotation === newQuotation.id_quotation)
          return newQuotation;
        return previousQuotation;
      });
    }

    return [newQuotation, ...existingQuotations].sort((a, b) => {
      if (a.draft === b.draft) return 0;
      return a.draft ? -1 : 1;
    });
  };

  const addNewFinalizedQuotation = (
    newQuotations: Quotation[],
    existingQuotations: Quotation[],
  ): Quotation[] => {
    if (newQuotations.length > 0) {
      const notExisting = newQuotations.filter(
        newQuotation =>
          !existingQuotations.some(existingQuotation => {
            if (
              existingQuotation.answers_quotation &&
              newQuotation.answers_quotation
            ) {
              return (
                existingQuotation.answers_quotation[0].id_answer_quotation ===
                newQuotation.answers_quotation[0].id_answer_quotation
              );
            }
            return false;
          }),
      );

      return [...notExisting, ...existingQuotations];
    }
    return existingQuotations;
  };

  const removeQuotation = (
    quotationToRemove: Quotation,
    existingQuotations: Quotation[],
  ): Quotation[] => {
    const updatedQuotations = existingQuotations.filter(
      quotation => quotation.id_quotation !== quotationToRemove.id_quotation,
    );

    return updatedQuotations;
  };

  const updateQuotation = (
    quotationToUpdate: Quotation,
    existingQuotations: Quotation[],
  ) => {
    const updatedQuotations = existingQuotations.map(existingQuotation => {
      if (existingQuotation.id_quotation === quotationToUpdate.id_quotation) {
        return quotationToUpdate;
      }
      return existingQuotation;
    });
    return updatedQuotations.sort((a, b) => {
      if (a.draft === b.draft) return 0;
      return a.draft ? -1 : 1;
    });
  };

  const updateQuotations = useCallback((quotationToUpdate: Quotation) => {
    setQuotationState(previous => ({
      answeredQuotationList: updateQuotation(
        quotationToUpdate,
        previous.answeredQuotationList,
      ),
      notAnsweredQuotationList: updateQuotation(
        quotationToUpdate,
        previous.notAnsweredQuotationList,
      ),
      finishedQuotationList: updateQuotation(
        quotationToUpdate,
        previous.finishedQuotationList,
      ),
    }));
  }, []);

  const finishQuotation = useCallback((quotationToRemove: Quotation) => {
    setQuotationState(previous => ({
      // ...previous,
      notAnsweredQuotationList: removeQuotation(
        quotationToRemove,
        previous.notAnsweredQuotationList,
      ),
      answeredQuotationList: removeQuotation(
        quotationToRemove,
        previous.answeredQuotationList,
      ),
      finishedQuotationList: addNewFinalizedQuotation(
        [quotationToRemove],
        previous.finishedQuotationList,
      ),
    }));
  }, []);

  useEffect(() => {
    socketInstance.on('quotation_workshop_excluded', (data: Quotation) => {
      console.log('excluida', data);
      setQuotationState(previous => ({
        notAnsweredQuotationList: removeQuotation(
          data,
          previous.notAnsweredQuotationList,
        ),
        answeredQuotationList: removeQuotation(
          data,
          previous.answeredQuotationList,
        ),
        finishedQuotationList: removeQuotation(
          data,
          previous.finishedQuotationList,
        ),
      }));
    });
    socketInstance.on('quotation_workshop_created', (data: Quotation) => {
      console.log('criada', data);

      setQuotationState(previous => ({
        notAnsweredQuotationList: addNewQuotation(
          data,
          previous.notAnsweredQuotationList,
        ),
        answeredQuotationList: removeQuotation(
          data,
          previous.answeredQuotationList,
        ),
        finishedQuotationList: removeQuotation(
          data,
          previous.finishedQuotationList,
        ),
      }));
    });
    socketInstance.on('quotation_workshop_finalized', (data: Quotation[]) => {
      console.log('totalmente finalizada', data);
      if (data.length > 0) {
        setQuotationState(previous => ({
          // ...previous,
          notAnsweredQuotationList: removeQuotation(
            data[0],
            previous.notAnsweredQuotationList,
          ),
          answeredQuotationList: removeQuotation(
            data[0],
            previous.answeredQuotationList,
          ),
          finishedQuotationList: addNewFinalizedQuotation(
            data,
            previous.finishedQuotationList,
          ),
        }));
      }
    });
    socketInstance.on(
      'quotation_workshop_parcial_finalized',
      (data: Quotation) => {
        console.log('parcialente finalizada(parcial)', data);
        setQuotationState(previous => ({
          ...previous,
          finishedQuotationList: addNewFinalizedQuotation(
            [data],
            previous.finishedQuotationList,
          ),
        }));
      },
    );
    socketInstance.on('quotation_workshop_responded', (data: Quotation) => {
      console.log('respondida', data);
      if (data) {
        setQuotationState(previous => ({
          // notAnsweredQuotationList: removeQuotation(
          //   quotation,
          //   previous.notAnsweredQuotationList,
          // ),
          ...previous,
          answeredQuotationList: addNewQuotation(
            data,
            previous.answeredQuotationList,
          ),
          // finishedQuotationList: removeQuotation(
          //   quotation,
          //   previous.finishedQuotationList,
          // ),
        }));
      }
    });

    return () => {
      socketInstance.off('quotation_workshop_excluded');
      socketInstance.off('quotation_workshop_created');
      socketInstance.off('quotation_workshop_responded');
      socketInstance.off('quotation_workshop_finalized');
      socketInstance.off('quotation_workshop_parcial_finalized');
    };
  }, [socketInstance]);

  useEffect(() => {
    fetchQuotations();
  }, [fetchQuotations]);

  const contextValue = useMemo(
    () => ({
      quotationState,
      setQuotationState,
      selectedPage,
      setSelectedPage,
      updateQuotations,
      finishQuotation,
    }),
    [quotationState, selectedPage, updateQuotations, finishQuotation],
  );

  return (
    <QuotationContext.Provider value={contextValue}>
      {children}
    </QuotationContext.Provider>
  );
};

export const useQuotation = () => {
  return useContext(QuotationContext) as QuotationContextType;
};
