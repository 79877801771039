import React, {
  CSSProperties,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { XSquare } from '@phosphor-icons/react';
import styles from './modal.module.css';

import logo from '../../assets/logo_icone.svg';
import pattern from '../../assets/img/modal-pattern.svg';

type ModalSizes = 'default' | 'large' | 'full';
interface Props {
  isOpen: boolean;
  onClose?: () => void;
  children: ReactNode;
  footer?: ReactNode;
  title: string;
  size?: ModalSizes;
  isLoading?: boolean;
  style?: CSSProperties;
  disableScroll?: boolean;
  container?: Element | DocumentFragment;
  subtitle?: ReactNode;
  bodyStyle?: string;
}

export const Modal = ({
  isOpen,
  onClose,
  children,
  title,
  size = 'default',
  isLoading = false,
  footer,
  style,
  disableScroll = true,
  container = document.body,
  subtitle,
  bodyStyle = '',
}: Props) => {
  const [isFocus, setIsFocus] = useState(false);
  const modalRef = useRef<HTMLDivElement>(null);
  const overlayRef = useRef<HTMLDivElement>(null);
  const titleRef = useRef<HTMLHeadingElement>(null);

  useEffect(() => {
    if (isOpen && modalRef.current) {
      modalRef.current.scrollIntoView({ behavior: 'auto', block: 'center' });
      if (disableScroll) {
        overlayRef.current?.focus();
        document.body.style.overflow = 'hidden';
      }

      // Focus on available title
      if (titleRef.current) {
        titleRef.current.focus();
      } else {
        // if cannot focus on title focus on first element in modal
        const firstFocusableElement = modalRef.current.querySelector(
          'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
        ) as HTMLElement | null;
        if (firstFocusableElement) {
          firstFocusableElement.focus();
        }
      }
    }

    return () => {
      console.log(isOpen);
      if (isOpen) document.body.style.overflow = 'auto';
    };
  }, [disableScroll, isOpen, isFocus]);

  return createPortal(
    isOpen ? (
      <div
        className={styles.overlay}
        ref={overlayRef}
        // make overlay focusable on Safari
        tabIndex={-1}
      >
        <div
          className={`${styles.content} ${styles[size]}`}
          ref={modalRef}
          style={style}
        >
          <div className={styles.header}>
            <hr />
            <img src={logo} alt="Logo" />
            <img src={pattern} alt="fundo" className={styles.pattern} />
            <div className={styles.content}>
              <h2 ref={titleRef} tabIndex={-1}>
                {title}
              </h2>{' '}
              {subtitle}
            </div>
            {/* Adiciona tabIndex para permitir foco */}
            {onClose && (
              <button type="button" onClick={onClose}>
                <XSquare size={36} color="var(--black)" />
              </button>
            )}
          </div>
          <div className={`${styles.body} ${bodyStyle}`}>
            {isLoading && (
              <div className={styles['loading-overlay']}>
                <span className={styles.loader} />
              </div>
            )}
            {!isLoading && children}
          </div>
          <footer className={styles.footer}>{footer}</footer>
        </div>
      </div>
    ) : null,
    container,
  );
};
