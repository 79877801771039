import {
  Quotation,
  QuotationDraftInput,
  Workshop,
} from '../../../../@types/interface';
import api from '../../../../services/api/api';
import {
  ApproveQuotationInput,
  ChangeQuotationStateInput,
  CreateQuotationInput,
  GetAllMyQuotationsResponse,
  GetMyQuotationsProps,
  GetQuotationPaymentTypes,
  IncreaseQuotationHoursInput,
  QuotationAnswer,
  SendQuotationInput,
} from './interface';

// Quotation
export const getMyQuotations = async ({
  limit,
  option,
  page,
  filter,
  filter_value,
}: GetMyQuotationsProps) => {
  const params: Partial<GetMyQuotationsProps> = {};
  if (limit) params.limit = limit;
  if (page) params.page = page;
  if (option) params.option = option;
  if (filter) params.filter = filter;
  if (filter_value) params.filter_value = filter_value;

  const { data } = await api.get<Quotation[]>('/quotation', {
    params,
  });

  return data;
};
export const getAllMyQuotations = async ({
  limit,
  page,
}: GetMyQuotationsProps) => {
  const params: Partial<GetMyQuotationsProps> = {};
  if (limit) params.limit = limit;
  if (page) params.page = page;

  const { data } = await api.get<GetAllMyQuotationsResponse>('/quotation', {
    params,
  });

  return data;
};

export const getQuotationById = async (id: string) => {
  const { data } = await api.get<Quotation>(`/quotation/${id}`);

  return data;
};

export const createQuotation = async ({
  comment,
  imagesList,
  items,
  vehicleId,
  hours,
  type_payment,
}: CreateQuotationInput) => {
  const formData = new FormData();
  formData.append('vehicle_id', vehicleId);
  formData.append('comment', comment);
  formData.append('items', JSON.stringify(items));
  if (hours) formData.append('hours', hours);
  if (type_payment && type_payment.trim().length > 0) {
    formData.append('type_payment', type_payment);
  }
  imagesList.forEach(image => {
    formData.append('file', image);
  });

  const { data } = await api.post<Quotation>('/quotation', formData);

  return data;
};

export const sendQuotation = async (props: SendQuotationInput) => {
  const { data } = await api.post<QuotationAnswer[]>('/quotation/send', {
    quotation_id: props.quotationId,
    send_to: props.sendTo,
  });

  return data;
};

export const resendQuotation = async (props: SendQuotationInput) => {
  const { data } = await api.put<QuotationAnswer[]>('/quotation/resend', {
    quotation_id: props.quotationId,
    send_to: props.sendTo,
  });

  return data;
};

export const approveQuotation = async (props: ApproveQuotationInput) => {
  const { data } = await api.post<QuotationAnswer[]>(
    '/quotation/answer/approve',
    {
      ...props,
    },
  );
  return data;
};

export const finishQuotation = async (id: string) => {
  const { data } = await api.post<Quotation>(`/quotation/finish/${id}`);
  return data;
};

export const blockQuotation = async (id: string) => {
  const { data } = await api.post<Quotation>(
    `/quotation/finish/${id}?action=block`,
  );
  return data;
};

export const changeQuotationState = async (
  props: ChangeQuotationStateInput,
) => {
  const { action, id } = props;
  const { data } = await api.post<Quotation>(
    `/quotation/changeState/${id}?action=${action}`,
  );
  return data;
};

export const increaseQuotationHours = async (
  props: IncreaseQuotationHoursInput,
) => {
  const { data } = await api.put<Quotation>(`/quotation/duration`, {
    ...props,
  });
  return data;
};

export const getQuotationPaymentTypes = async () => {
  const { data } = await api.get<GetQuotationPaymentTypes>(
    '/quotation/type_payment',
  );
  return data;
};

export const deleteQuotation = async (id: string) => {
  const { data } = await api.delete(`/quotation/${id}`);
  return data;
};

// Workshops
export const findFavoritesWorkshops = async (name = '', type = '') => {
  const { data } = await api.get<Workshop[]>('/workshop/favorites', {
    params: {
      name,
      type,
    },
  });

  return data;
};

export const createQuotationDraft = async () => {
  const { data } = await api.post<Quotation>('/quotation/draft', {
    comment: '',
    vehicle_plate: '',
    items: [],
  });

  return data;
};

export const updateQuotationDraft = async (props: QuotationDraftInput) => {
  const { data } = await api.post<Quotation>('/quotation/draft', { ...props });

  return data;
};

export const transformDraftInQuotation = async (id: string) => {
  const { data } = await api.put<Quotation>(`/quotation/draft/transform/${id}`);

  return data;
};
