import { PlusCircle, PlusSquare } from '@phosphor-icons/react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import styles from './created-quotes.module.css';
import { Button } from '../../../../../../shared/Button';
import createdQuotationsIcon from '../../../../../../assets/icons/created-quotations.svg';
import { useCreatedQuotation } from './hooks/useCreatedQuotation';
import { useChangeQuotationState } from '../../hooks/useChangeQuotationState';
import { useResponsive } from '../../../../../../hooks/useResponsive';
import { FilterComponent } from '../../../../../../shared/FilterComponent';
import { useCreatedQuotationData } from './hooks/useCreatedQuotationData';
import { Quotation } from '../../../../../../@types/interface';
import { QuotationFilterOptions } from '../../interface';
import {
  createQuotationDraftController,
  deleteQuotationController,
} from '../../controller';
import { CreateQuotationModal } from './components/CreateQuotationModal';
import { FinishModal } from './components/FinishModal';
import { RedoQuotationModal } from './components/RedoQuotationModal';
import { EditQuotation } from './components/EditQuotation';
import { AddCompanyModal } from './components/AddCompanyModal';
import { useQuotation } from '../../../../../../contexts/quotationContext';
import { ViewQuotationModal } from '../../components/ViewQuotationModal';
import { QuotationList } from './components/QuotationListItem';
import useViewModal from '../../hooks/useViewModal';
import useSelectedQuotation from '../../hooks/useSelectedQuotation';
import useAddCompanyModal from '../../hooks/useAddCompanyModal';
import useRedoModal from '../../hooks/useRedoModal';
import useFinishModal from '../../hooks/useFinishModal';
import useCreateQuotationModal from '../../hooks/useCreateQuotationModal';
import { FeedbackModal } from '../../../../../../shared/FeedbackModal';
import { RemoveDraftModal } from './components/RemoveDraftModal';
import useFeedbackModal from '../../hooks/useFeedbackModal';

export const CreatedQuotations = () => {
  const {
    handleFinishQuotation: handleFinish,
    isLoading,
    page,
    searchedQuotations,
    loadMoreData,
    fetchQuotationsByFilter,
  } = useCreatedQuotationData();

  const {
    handleRowClick,
    filterType,
    setFilterType,
    handleDateSearch,
    handleSearch,
    filterValue,
  } = useCreatedQuotation({
    fetchQuotationsByFilter,
  });

  const { setQuotationState } = useQuotation();

  const selectedQuotation = useSelectedQuotation();
  const viewModal = useViewModal();
  const addCompanyModal = useAddCompanyModal();
  const redoModal = useRedoModal();
  const finishModal = useFinishModal();
  const createModal = useCreateQuotationModal();
  const feedbackModal = useFeedbackModal();

  const { changeQuotationState, isChangingQuotationState } =
    useChangeQuotationState({
      onChangeState: (res: Quotation) =>
        handleFinish(res, () => {
          // if is redoing a quotation will need selectedQuotation state
          if (!redoModal.isOpen) selectedQuotation.onClear();
        }),
    });
  const { sizeMobile } = useResponsive();
  const { quotationState, finishQuotation } = useQuotation();

  const [searchParams, setSearchParams] = useSearchParams();
  const [quotationIdParam, setQuotationIdParam] = useState('');

  const handleGetQuotationByIdParam = useCallback(async () => {
    const quotationId = searchParams.get('id');
    const currentPage = searchParams.get('page');
    if (quotationId && currentPage === 'created') {
      setQuotationIdParam(quotationId);
      viewModal.onOpen();
      setSearchParams({});
    }
  }, [searchParams, setSearchParams, viewModal]);

  useEffect(() => {
    handleGetQuotationByIdParam();
  }, [handleGetQuotationByIdParam]);

  const isEmpty = useMemo(() => {
    return !isLoading && quotationState.notAnsweredQuotationList.length === 0;
  }, [isLoading, quotationState.notAnsweredQuotationList.length]);

  const notFound = useMemo(() => {
    return filterValue.trim().length > 0 && searchedQuotations.length === 0;
  }, [filterValue, searchedQuotations.length]);

  const handleOpenCreateModal = async () => {
    try {
      feedbackModal.onOpen();
      const res = await createQuotationDraftController();
      if (res) {
        selectedQuotation.onSelect(res);
        setQuotationState(previous => {
          return {
            ...previous,
            notAnsweredQuotationList: [
              res,
              ...previous.notAnsweredQuotationList,
            ],
          };
        });
      }

      document.body.style.overflow = 'hidden';
      createModal.onOpen();
    } catch (error) {
      console.error(error);
    } finally {
      feedbackModal.onClose();
    }
  };

  const handleFinishQuotation = useCallback(async () => {
    const res = await changeQuotationState({
      id: selectedQuotation.quotation?.id_quotation ?? '',
      action: 'finish',
    });
    finishModal.onClose();
    selectedQuotation.onClear();

    if (res) {
      finishQuotation(res);
    }
  }, [
    changeQuotationState,
    finishModal.onClose,
    selectedQuotation?.quotation,
    finishQuotation,
  ]);

  const handleRemoveDraft = useCallback(
    async (draftId: string) => {
      try {
        feedbackModal.onOpen();
        await deleteQuotationController(draftId);
      } catch (error) {
        console.error(error);
      } finally {
        feedbackModal.onClose();
      }
    },
    [feedbackModal.onOpen, feedbackModal.onClose],
  );

  return (
    <>
      <FeedbackModal isOpen={feedbackModal.isOpen} variant="sending" />
      <RemoveDraftModal />
      <ViewQuotationModal
        isOpen={viewModal.isOpen}
        onClose={() => {
          viewModal.onClose();
          selectedQuotation.onClear();
          setQuotationIdParam('');
        }}
        quotation={selectedQuotation.quotation}
        isOnCreationView
        quotationId={quotationIdParam}
      />
      <AddCompanyModal
        isOpen={addCompanyModal.isOpen}
        onClose={() => addCompanyModal.onClose()}
        defaultQuotation={selectedQuotation.quotation}
      />
      <RedoQuotationModal
        isOpen={redoModal.confirmRedo}
        onClose={() => redoModal.onConfirmRedoClose()}
        onConfirm={() => {
          redoModal.onConfirmRedoClose();
          redoModal.onOpen();
        }}
        quotation={selectedQuotation.quotation}
      />
      <CreateQuotationModal
        title="Criar Cotação"
        onRemoveQuotation={handleRemoveDraft}
      />
      <EditQuotation
        isOpen={redoModal.isOpen}
        onClose={() => {
          selectedQuotation.onClear();
          redoModal.onClose();
        }}
        onFinish={async () => {
          await deleteQuotationController(
            selectedQuotation?.quotation?.id_quotation ?? '',
          );
        }}
        defaultQuotation={selectedQuotation.quotation}
        title="Refazer Cotação"
      />
      <FinishModal
        isLoading={isChangingQuotationState}
        isOpen={finishModal.isOpen}
        onClose={() => {
          finishModal.onClose();
          selectedQuotation.onClear();
        }}
        onFinish={handleFinishQuotation}
        quotation={selectedQuotation.quotation}
      />

      <div className={styles.heading}>
        <div className={styles.describe}>
          <img src={createdQuotationsIcon} alt="cotações criadas" />
          <p>Cotações aguardando resposta</p>
        </div>
        <div className={styles.actions}>
          <FilterComponent
            handleDateSearch={handleDateSearch}
            handleSearch={handleSearch}
            handleFilterChange={(filter: string) =>
              setFilterType(filter as QuotationFilterOptions)
            }
            defaultValue="code"
            variant={
              filterType === 'code' || filterType === 'plate'
                ? 'searchbar'
                : 'date'
            }
            upperCase={filterType === 'plate'}
            onlyNumber={filterType === 'code'}
            options={[
              {
                label: 'Número',
                value: 'code',
              },
              {
                label: 'Placa',
                value: 'plate',
              },
              {
                label: 'Data',
                value: 'date',
              },
            ]}
          />

          <Button
            handleClick={handleOpenCreateModal}
            style={{
              whiteSpace: 'nowrap',
              padding: '0.875rem 0.625rem',
              borderRadius: '10px',
              backgroundColor: 'var(--primary)',
            }}
          >
            {!sizeMobile ? <PlusCircle size={32} /> : <PlusSquare size={32} />}
            Nova Cotação
          </Button>
        </div>
      </div>
      <QuotationList
        handleRowClick={handleRowClick}
        quotationList={
          filterValue.trim().length > 0
            ? searchedQuotations
            : quotationState.notAnsweredQuotationList
        }
        onScroll={() => {
          loadMoreData(page);
        }}
        isLoading={isLoading}
        isEmpty={isEmpty}
        notFound={notFound}
      />
    </>
  );
};
