import { useEffect, useState } from 'react';
import { FiAlertTriangle } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Input, Text } from 'native-base';
import { useAuth } from '../../../hooks/useAuth';
import api from '../../../services/api/api';
import { maskPhone } from '../../../services/helpers/mask';
import { MainComponent } from '../../../shared/MainComponent/MainComponent';
import { ModalSuccess } from '../../../shared/ModalSuccess/ModalSuccess';

import { Container, Section, ListRow, SpanMsg } from './_userEdit';
import message from '../../../utils/message';
import { COLORS } from '../../../styles/colors';

interface UserEditProps {
  messageToUpdate?: string;
}

const UserEdit = ({ messageToUpdate = '' }: UserEditProps) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [newPassword, setNewPass] = useState<string>('');
  const [confirmPassword, setNewPassConfirm] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const { user, setUser } = useAuth();
  const navigate = useNavigate();

  interface UserProps {
    id_user: string;
    name: string;
    surname: null;
    email: string;
    phone: null;
    avatar: null;
    role_name: string;
    vehicles: [];
    workshop: Workshop;
    created_at: string;
    updated_at: string;
  }
  interface Workshop {
    id_workshop: string;
    cnpj: string;
    corporate_name: string;
    fantasy_name: string;
    phone: string;
    whatsapp: string;
    email: string;
    active: boolean;
    address_id: string;
    user_id: string;
    plan_id: null;
    created_at: string;
    updated_at: string;
    inbox_id: string | null;
  }

  useEffect(() => {
    setName(user.name ?? '');
    setPhone(user.phone ?? '');
  }, [user]);

  const notifyError = (msg: string) => message(msg, 'error');

  const updateApiUser = async () => {
    try {
      const { data } = await api.put<UserProps>('/user/self', {
        name,
        phone,
        newPassword,
      });

      setUser(data);
      localStorage.setItem('@AutoCenter: user', JSON.stringify(data));
      setShowModal(true);
    } catch (error) {
      notifyError('Erro em alterar dados de acesso!');
    } finally {
      navigate('/page-login');
    }
  };

  const handleSubmit = () => {
    if (newPassword !== confirmPassword)
      return notifyError('Confirmação de senha não confere!');

    return updateApiUser();
  };

  return (
    <Container>
      <MainComponent title="Editar Dados de Acesso">
        <Section>
          <ListRow>
            {messageToUpdate && (
              <SpanMsg>
                {messageToUpdate} <FiAlertTriangle className="icon" />
              </SpanMsg>
            )}
            <Box>
              <Text fontWeight="500">Nome:</Text>
              <Input
                placeholder="Nome de usuário"
                onChange={(e: any) => setName(e.target.value)}
                value={name}
              />
            </Box>

            <Box>
              <Text fontWeight="500">Telefone:</Text>
              <Input
                placeholder="Ex.: (xx) xxxxx-xxxx"
                onChange={(e: any) => setPhone(maskPhone(e.target.value))}
                value={maskPhone(phone)}
                maxLength={15}
              />
            </Box>

            <Box>
              <Text fontWeight="500">Nova Senha:</Text>
              <Input
                type="password"
                placeholder="Ex.: ***********"
                onChange={(e: any) => setNewPass(e.target.value)}
                value={newPassword}
              />
            </Box>

            <Box>
              <Text fontWeight="500">Confirme a Senha:</Text>
              <Input
                type="password"
                placeholder="Ex.: ***********"
                onChange={(e: any) => setNewPassConfirm(e.target.value)}
                value={confirmPassword}
              />
            </Box>
            <Button onPress={() => handleSubmit()} bg={COLORS.GREEN} mt="1rem">
              Salvar
            </Button>
          </ListRow>
        </Section>
      </MainComponent>

      <ModalSuccess setShowModal={setShowModal} showModal={showModal}>
        Alterado com sucesso!
      </ModalSuccess>
    </Container>
  );
};

export default UserEdit;
