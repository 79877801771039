import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Quote } from './useCreateQuotation';
import { VehicleResponse } from '../../../../../../../../services/dataFetchers/VehicleDataFetcher/model';
import {
  getQuotationByIdController,
  getQuotationPaymentTypesController,
} from '../../../../controller';
import { PaymentTypes } from '../../../../interface';
import { fetchVehicleDataByLicensePlateController } from '../../../../../../../../services/dataFetchers/VehicleDataFetcher/VehicleDataFetcher';
import useCreateQuotationModal from '../../../../hooks/useCreateQuotationModal';

export interface FormValues {
  vehicleId: string;
  plate?: string;
  vehicle?: string;
  chassi?: string;
  year?: string;
  model?: string;
  quotes: Quote[];
  comment?: string;
  // hours?: string;
  paymentMethod?: string;
}

const defaultValues = {
  vehicleId: '',
  chassi: '',
  model: '',
  plate: '',
  quotes: [
    {
      description: '',
      quantity: '',
      reference: '',
    },
  ],
  vehicle: '',
  year: '',
  comment: '',
  paymentMethod: '',
};

const schema = yup.object().shape({
  vehicleId: yup.string(),
  plate: yup.string(),
  vehicle: yup.string(),
  chassi: yup.string(),
  year: yup.string(),
  model: yup.string(),
  quotes: yup
    .array()
    .of(
      yup.object().shape({
        description: yup.string().required('O campo descrição é obrigatório'),
        reference: yup.string(),
        quantity: yup.string().required('O campo quantidade é obrigatório'),
      }),
    )
    .min(1, 'informe ao menos um item'),
  comment: yup.string(),
});

interface Props {
  draftId?: string;
}

export const useQuotationForm = ({ draftId }: Props) => {
  const {
    control,
    getValues,
    reset,
    trigger,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema),
  });
  const {
    fields: quotes,
    append,
    remove,
  } = useFieldArray({ control, name: 'quotes' });
  const [imagesList, setImagesList] = useState<File[]>([]);
  const [paymentTypes, setPaymentTypes] = useState<PaymentTypes[]>([]);
  const [isLoadingData, setIsLoadingData] = useState(false);
  const createModal = useCreateQuotationModal();

  const handleImageChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (files) {
      const newImage = files[0];
      setImagesList(previousImages => {
        if (previousImages.length < 3) {
          return [...previousImages, newImage];
        }
        return [...previousImages.slice(1), newImage];
      });
    }
  };

  const handleRemoveImage = (name: string) => {
    setImagesList(previousImages => {
      const listImageAfterRemove = previousImages.filter(
        image => image.name !== name,
      );
      return [...listImageAfterRemove];
    });
  };

  const handleAddRow = () => {
    return append({
      description: '',
      quantity: '',
      reference: '',
    });
  };

  const resetFormValues = () => {
    reset(defaultValues);
    setImagesList([]);
  };

  const handleVehicleFields = (vehicle: VehicleResponse) => {
    const formValues = getValues();
    reset({
      ...formValues,
      chassi: vehicle.chassi ?? '',
      model: vehicle.model ?? '',
      vehicle: vehicle.name ?? '',
      year: vehicle.year ?? '',
      vehicleId: vehicle.id_vehicle,
    });
  };

  const fetchPaymentTypes = async () => {
    const res = await getQuotationPaymentTypesController();

    if (res && res.options) {
      setPaymentTypes(res.options);
    }
  };

  const fetchQuotation = useCallback(
    async (id: string) => {
      setIsLoadingData(true);
      const defaultQuotation = await getQuotationByIdController(id);

      if (defaultQuotation) {
        const plate = defaultQuotation?.buffer_plate ?? '';
        const items =
          defaultQuotation.buffer_items_objs &&
          defaultQuotation.buffer_items_objs?.length > 0
            ? defaultQuotation.buffer_items_objs
            : [
                {
                  description: '',
                  quantity: '',
                  reference: '',
                },
              ];

        reset({
          plate: defaultQuotation?.buffer_plate ?? '',
          quotes: items,
          comment: defaultQuotation?.comment ?? '',
          paymentMethod: defaultQuotation?.type_payment ?? '',
        });
        if (plate.trim().length > 0) {
          const defaultVehicle = await fetchVehicleDataByLicensePlateController(
            plate,
          );
          reset(previous => ({
            ...previous,
            vehicleId: defaultVehicle?.id_vehicle ?? '',
            vehicle: defaultVehicle?.name ?? '',
            chassi: defaultVehicle?.chassi ?? '',
            model: defaultVehicle?.model ?? '',
            year: defaultVehicle?.year ?? '',
          }));
        }
      }

      setIsLoadingData(false);
    },
    [reset],
  );

  const checkIsFormEmpty = useCallback(() => {
    const { quotes: items, plate, paymentMethod, comment } = getValues();

    const isItemsEmpty = items.every(
      item =>
        (!item.description || item.description.length === 0) &&
        (!item.quantity || item.quantity.length === 0) &&
        (!item.reference || item.reference.length === 0),
    );

    return (
      isItemsEmpty &&
      (!plate || plate.length === 0) &&
      (!paymentMethod || paymentMethod.length === 0) &&
      (!comment || comment.length === 0)
    );
  }, [getValues]);

  useEffect(() => {
    if (draftId && draftId.trim().length > 0 && createModal.isOpen) {
      fetchQuotation(draftId);
    } else {
      reset(defaultValues);
    }
  }, [draftId, fetchQuotation, reset, createModal.isOpen]);

  useEffect(() => {
    fetchPaymentTypes();
  }, []);

  return {
    control,
    imagesList,
    getValues,
    resetFormValues,
    handleImageChange,
    handleRemoveImage,
    handleAddRow,
    reset,
    handleVehicleFields,
    quotes,
    errors,
    trigger,
    remove,
    paymentTypes,
    isLoadingData,
    checkIsFormEmpty,
  };
};
