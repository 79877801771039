/* eslint-disable react/jsx-no-constructed-context-values */
import React, {
  createContext,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { UserClass, Workshop } from './interface';
import { getAccessToken, getUserData } from '../utils/workshopData';
import useLoginModal from './useLoginModal';
import { LoginModal } from '../shared/LoginModal';
import api from '../services/api/api';
import { AuthenticationResponse } from '../@types/interface';
import { saveObjectLocally } from '../utils/localStorage';

interface IUserProvider {
  user: UserClass;
  workshop: Workshop | null;

  setUser: React.Dispatch<React.SetStateAction<UserClass>>;
  setWorkshop: React.Dispatch<React.SetStateAction<Workshop>>;
  authenticate: () => void;
}

const AuthContext = createContext({} as IUserProvider);

const AuthProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  const [user, setUser] = useState<UserClass>({} as UserClass);
  const [workshop, setWorkshop] = useState<Workshop>({} as Workshop);

  const loginModal = useLoginModal();
  const loggedUserVerification = (userValue: string) => {
    if (!userValue) return;

    setUser(JSON.parse(userValue));
    const workshopInfo = JSON.parse(userValue);
    setWorkshop(workshopInfo.workshop);
  };

  const revalidate = useCallback(async () => {
    try {
      const { data } = await api.post<AuthenticationResponse>('/auth/token');
      if (data) {
        saveObjectLocally('@AutoCenter: user', data.user);
        localStorage.setItem('@AutoCenter: accessToken', data.accessToken);
      }
    } catch (err) {
      loginModal.onOpen();
      console.error(err);
    }
  }, [loginModal.onOpen]);

  const authenticate = useCallback(async () => {
    const localUser = getUserData();
    const accessToken = getAccessToken();
    if (!localUser || !accessToken) {
      window.location.href = '/page-login';
      return;
    }
    await revalidate();
  }, [revalidate]);

  useEffect(() => {
    const userJson = localStorage.getItem('@AutoCenter: user');
    if (userJson) loggedUserVerification(userJson);
  }, []);

  return (
    <AuthContext.Provider
      value={{ user, setUser, workshop, setWorkshop, authenticate }}
    >
      {children}
      <LoginModal />
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuth = () => useContext(AuthContext);
